import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const activeStyle = ({ isActive }) => {
  return {
    fontWeight: isActive && "bold",
    color: isActive && "#f20a0e",
    fontSize: 15,
    paddingInline: 16,
    textDecoration: isActive && "underline",
    cursor: "pointer",
  };
};

function MainMenu() {
  const { t } = useTranslation();

  return (
    <div className="mainMenu-container">
      <NavLink to="/" style={activeStyle} className="nav-btn">
        Home
      </NavLink>
      <NavLink to="features" style={activeStyle} className="nav-btn">
        {t("Features")}
      </NavLink>
      {/* <NavLink to="pro" style={activeStyle} className="nav-btn">
        Fishingfinity Pro
      </NavLink> */}
      <NavLink to="download-app" style={activeStyle} className="nav-btn">
        {t("Download App")}
      </NavLink>
    </div>
  );
}

export default MainMenu;
