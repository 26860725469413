import { useTranslation } from "react-i18next";

const tableData = [
  {
    id: 0,
    title: "Access 7 days Weather Forecast",
    free: "close",
    pro: "check",
  },
  {
    id: 1,
    title: "Access 3 days Weather Forecast",
    free: "check",
    pro: "check",
  },
  {
    id: 2,
    title: "Advertisement",
    free: "check",
    pro: "close",
  },
  {
    id: 3,
    title: "Pro Fishingfinity certified Badge",
    free: "close",
    pro: "check",
  },
  {
    id: 4,
    title: "Access to View Logs of your catches on the Map",
    free: "close",
    pro: "check",
  },
  {
    id: 5,
    title: "View catches on the Map",
    free: "close",
    pro: "check",
  },
];

export default function PricingScreen() {
  const { t } = useTranslation();

  const pricingBoxContent = ({ title, amount, subs, due }) => {
    return (
      <ul className="price">
        <li className="header" style={{ backgroundColor: "#04aa6d" }}>
          {t(title)}
        </li>
        <li className={due === "year" ? "amountYearly" : "amount"}>{amount}</li>
        {due === "year" && <p className="text-center">{t("Save 16% off")}</p>}
        {tableData.map((d) => (
          <li key={d.id}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <span style={{ paddingRight: 15 }}>
                {subs === "pro" ? (
                  d.pro === "close" ? (
                    <p style={{ color: "red", fontSize: 15 }}>&#x2718;</p>
                  ) : (
                    <p style={{ color: "#04aa6d", fontSize: 15 }}>&#x2714;</p>
                  )
                ) : d.free === "close" ? (
                  <p style={{ color: "red", fontSize: 15 }}>&#x2718;</p>
                ) : (
                  <p style={{ color: "#04aa6d", fontSize: 15 }}>&#x2714;</p>
                )}
              </span>
              {t(d.title)}
            </div>
          </li>
        ))}
        <li className="grey">
          <a href="download-app" className="buttonPrice">
            {t("Download the app")}
          </a>
        </li>
      </ul>
    );
  };

  return (
    <div className="pro-main-container">
      <h1 className="pro-title">{t("Become a Fishingfinity PRO Member")}</h1>
      <div className="row">
        {/* Free */}
        {pricingBoxContent({
          title: "Free",
          amount: `€0/${t("month")}`,
          subs: "free",
          due: "month",
        })}

        {/* yearly Pro */}
        {pricingBoxContent({
          title: "Pro Yearly",
          amount: `€27.99/${t("year")}`,
          subs: "pro",
          due: "year",
        })}

        {/* monthly Pro */}
        {pricingBoxContent({
          title: "Pro Monthly",
          amount: `€2.79/${t("month")}`,
          subs: "pro",
          due: "month",
        })}
      </div>
    </div>
  );
}
