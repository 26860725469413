import { Outlet } from "react-router-dom";

import MainFooter from "../Footer";
import NavBar from "../Header/navBar";

const Root = () => {
  return (
    <div>
      <NavBar />
      <main>
        <Outlet />
      </main>
      <MainFooter />
    </div>
  );
};

export default Root;
