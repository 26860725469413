import { useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import { useTranslation } from "react-i18next";

import { db } from "../config/fb";
import PopUp from "../components/PopUp";

const Contact = () => {
  const [contactInfo, setContactInfo] = useState({
    email: "",
    subject: "",
    message: "",
    fullname: "",
  });
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  const { email, message, subject, fullname } = contactInfo;
  const { t } = useTranslation();

  const htmlDesign = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
  <meta http-equiv="Content-Type" content="text/html charset=UTF-8" />
  <html lang="en">
    <head></head>
    <body style="background-color:#ffffff;font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,Roboto,Oxygen-Sans,Ubuntu,Cantarell,&quot;Helvetica Neue&quot;,sans-serif">
      <table align="center" role="presentation" cellSpacing="0" cellPadding="0" border="0" width="100%" style="max-width:37.5em;margin:0 auto;padding:20px 25px 48px;background-image:url(&quot;/assets/raycast-bg.png&quot;);background-position:bottom;background-repeat:no-repeat, no-repeat">
        <tr style="width:100%">
          <td>
          <img alt="Raycast" src="https://firebasestorage.googleapis.com/v0/b/fishingfinity-bytycoonlures.appspot.com/o/FIAMImages%2F1024x1024.png?alt=media&token=06119958-7925-4ea0-aff4-a219da97a14e" width="48" height="48" style="display:block;outline:none;border:none;text-decoration:none" />
            <table style="margin:24px 0" align="center" border="0" cellPadding="0" cellSpacing="0" role="presentation" width="100%">
              <tbody>
                <tr>
                 <p style="font-size:14px">From: ${fullname} < ${email} > <a target="_blank" style="color:#15c;text-decoration:underline;font-size:12px;margin:0;padding:0;line-height:1.4"></a></p>
                  <p style="font-size:14px">Subject: ${subject}</p>
                  <td>
                    <p style="font-size:14px">Message body: </p>
                    <pre style="font-size:16px;line-height:26px">${message}</pre>
                  </td>
                </tr>
              </tbody>
            </table>
            <p style="font-size:13px;line-height:24px;margin:0;color:#AFAFAF">---</p> 
             <p style="font-size:12px">This e-mail was sent from a contact form on Fishingfinity.(https://fishingfinity.com/) <a target="_blank" style="color:#15c;text-decoration:underline;font-size:10px;margin:0;padding:0;line-height:1.4"></a></p>
            <hr style="width:100%;border:none;border-top:1px solid #eaeaea;border-color:#dddddd;margin-top:20px" />
             <p style="font-size:13px;line-height:24px;margin:0;color:#AFAFAF;text-align:center">© 2022-2023 Fishingfinity. All Rights Reserved.</p>
            <p style="font-size:13px;line-height:24px;margin:0;color:#AFAFAF;text-align:center">ARBEST S.N.C di Reishei Aurellano.</p> 
          </td>
        </tr>
      </table>
    </body>
  </html>`;

  const handeleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "Mail"), {
        to: ["Fishingfinity@gmail.com"],
        message: {
          subject,
          html: htmlDesign,
        },
      }).then(() => {
        setOpen(true);
        setContactInfo({
          email: "",
          subject: "",
          message: "",
          fullname: "",
        });
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  return (
    <div className="contact-form-container">
      <h1>{t("Contact Us")}</h1>
      <p>
        {t("Please fill in the information below to contact Fishingfinity.")}
      </p>
      <div className="contact-form-box">
        <form id="contact-form" name="contact-form" action="#">
          <div>
            <label htmlFor="email">{t("Your email")}</label>
            <input
              type="text"
              id="email"
              name="email"
              autoComplete="email"
              value={email}
              onChange={({ target }) =>
                setContactInfo({ ...contactInfo, email: target.value })
              }
              placeholder="name@example.com"
              required
            />
          </div>
          <div>
            <label htmlFor="fullname">{t("Your Name")}</label>
            <input
              type="text"
              id="fullname"
              name="fullname"
              value={fullname}
              autoComplete="name"
              onChange={({ target }) =>
                setContactInfo({ ...contactInfo, fullname: target.value })
              }
              placeholder={t("Your Name")}
              required
            />
          </div>
          <div>
            <label htmlFor="subject">{t("Subject")}</label>
            <input
              type="text"
              id="subject"
              name="subject"
              value={subject}
              autoComplete="off"
              onChange={({ target }) =>
                setContactInfo({ ...contactInfo, subject: target.value })
              }
              placeholder={t("Let us know how we can help you")}
              required
            />
          </div>
          <div>
            <label htmlFor="message">Your message</label>
            <textarea
              id="message"
              name="message"
              value={message}
              rows="6"
              onChange={({ target }) =>
                setContactInfo({ ...contactInfo, message: target.value })
              }
              placeholder={t("Leave a comment") + "..."}
              required
            />
          </div>
          <button onClick={handeleSubmit} type="submit">
            {t("Send message")}
          </button>
          {/* <input type="submit" value={t("Send message")}></input> */}
        </form>
      </div>
      <PopUp open={open} closeModal={closeModal} />
    </div>
  );
};

export default Contact;
