import React from "react";

import ReactDOM from "react-dom/client";
import App from "./App";

import reportWebVitals from "./reportWebVitals";
import "./Translation/i18n";

// import "./styles/App.css";
import "./styles/index.css";
import "./styles/pro.css";
import "./styles/home.css";
import "./styles/general.css";
import "./styles/components.css";
import "./styles/footer.css";
import "./styles/header.css";
import "./styles/qrCode.css";
import "./styles/features.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
reportWebVitals();
