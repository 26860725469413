import React from "react";
import { Link } from "react-router-dom";

import AppStoreImgBadge from "../images/app-store-button.png";
import GPlayImgBadge from "../images/play-store-button.png";

const playStoreUrl =
  "https://play.google.com/store/apps/details?id=com.tycoonlures.fishingfinity&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1";
const appStoreUrl = "https://apps.apple.com/it/app/fishingfinity/id1616848813";

export const DownloadBtn = ({ flexTo }) => {
  return (
    <div className={"download-container"}>
      <div>
        <Link to={playStoreUrl} target="_blank" rel="noopener noreferrer">
          <img alt="Get it on Google Play" src={GPlayImgBadge} />
        </Link>
      </div>

      <div>
        <Link to={appStoreUrl} target="_blank" rel="noopener noreferrer">
          <img alt="Download on App Store" src={AppStoreImgBadge} />
        </Link>
      </div>
    </div>
  );
};

export default DownloadBtn;
