import React from "react";
import { useTranslation } from "react-i18next";
import { LANGUAGES } from "../constant/arrayList";

function DropDownMenu() {
  const { i18n } = useTranslation();

  const onChangeLang = (e) => {
    const lang_code = e.target.value;
    i18n.changeLanguage(lang_code);
  };

  return (
    <select
      defaultValue={i18n.language}
      onChange={onChangeLang}
      className="select-lang-container"
    >
      {LANGUAGES.map(({ code, flag, label }) => (
        <option key={code} value={code}>
          {flag}
        </option>
      ))}
    </select>
  );
}

export default DropDownMenu;
