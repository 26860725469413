import TopBanner from "./TopBanner";
import SlideShow from "./SlideShow";

function HomeScreen() {
  return (
    <div className="home-container">
      <TopBanner />
      {/* <div className="home-background"><Features /></div> */}
      <SlideShow />
    </div>
  );
}

export default HomeScreen;
