import * as React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

function MobileMenu() {
  const { t } = useTranslation();

  const activeStyle = ({ isActive }) => {
    return {
      fontWeight: isActive && "bold",
      color: isActive && "#f20a0e",
      fontSize: 18,
      textDecoration: isActive && "underline",
      cursor: "pointer",
    };
  };

  return (
    <div className="mobileMenu-dropdown">
      <ul>
        <NavLink to="/" style={activeStyle} className="nav-btn">
          Home
        </NavLink>
      </ul>
      <ul>
        <NavLink to="features" style={activeStyle} className="nav-btn">
          {t("Features")}
        </NavLink>
      </ul>
      {/* <ul>
        <NavLink to="pro" style={activeStyle} className="nav-btn">
          Fishingfinity Pro
        </NavLink>
      </ul> */}
      <ul>
        <NavLink to="download-app" style={activeStyle} className="nav-btn">
          {t("Download App")}
        </NavLink>
      </ul>
    </div>
  );
}

export default MobileMenu;
