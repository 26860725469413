import React from "react";
import { FaUsers } from "react-icons/fa";
import { TbListDetails } from "react-icons/tb";
import { TiWeatherPartlySunny } from "react-icons/ti";
import { AiOutlineSafety } from "react-icons/ai";
import { useTranslation } from "react-i18next";
// import { useParallax } from "react-scroll-parallax";

import iPhoneFrame from "../images/frameNoCamera.png";
import iPhoneFrameWCamera from "../images/iPhoneFrame.png";

import feedEn from "../assets/screenshotsEn/feed.png";
import logEn from "../assets/screenshotsEn/log.png";
import forecastEn from "../assets/screenshotsEn/forecast.png";
import privacyEn from "../assets/screenshotsEn/privacy.png";
import tempChartEn from "../assets/screenshotsEn/tempChartEn.png";

import feedIt from "../assets/screenshotsIt/feed.png";
import logIt from "../assets/screenshotsIt/log.png";
import forecastIt from "../assets/screenshotsIt/forecast.png";
import privacyIt from "../assets/screenshotsIt/privacy.png";
import tempChartIt from "../assets/screenshotsIt/tempChartIt.png";

function Features() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const lang = i18n.language;

  const feed = lang === "it" ? feedIt : feedEn;
  const log = lang === "it" ? logIt : logEn;
  const forecast = lang === "it" ? forecastIt : forecastEn;
  const privacy = lang === "it" ? privacyIt : privacyEn;
  const tempChart = lang === "it" ? tempChartIt : tempChartEn;

  // const FeedTextRef = useParallax({
  //   translateX: [50, -100],
  // });

  // const LogTextRef = useParallax({
  //   translateY: [100, -100],
  // });
  // const ForecasetTextRef = useParallax({
  //   translateY: [100, -100],
  // });

  // const PrivacyTextRef = useParallax({
  //   translateY: [100, -100],
  // });

  return (
    <div className="features-container">
      <h1>{t("FEATURES")}</h1>
      <div className="features-grid">
        <div className="features-box">
          <div className="features-box-title">
            <FaUsers size={35} />
            <h2>{t("Connect and Share")}</h2>
          </div>
          <p>{t("Connect and Share details")}</p>
        </div>
        <div className="features-box">
          <div className="features-box-images">
            <div className="features-item-image">
              <img src={iPhoneFrame} alt="iPhone Frame" />
              <img src={feed} alt="features item img" />
            </div>
          </div>
        </div>
      </div>

      <div className="features-grid">
        <div className="features-box">
          <div className="features-box-images">
            <div className="features-item-image">
              <img src={iPhoneFrame} alt="iPhone Frame" />
              <img src={log} alt="features item img" />
            </div>
          </div>
        </div>
        <div className="features-box">
          <div className="features-box-title">
            <TbListDetails size={35} />
            <h2>{t("Log Catches")}</h2>
          </div>
          <p>{t("Log Catches details")}</p>
        </div>
      </div>

      <div className="features-grid">
        <div className="features-box">
          <div className="features-box-title">
            <TiWeatherPartlySunny size={35} />
            <h2>{t("Marine Weather Forecast")}</h2>
          </div>
          <p>{t("Marine Weather Forecast details")}</p>
        </div>
        <div className="features-box">
          <div className="features-box-images">
            <div className="features-item-image">
              <img src={iPhoneFrame} alt="iPhone Frame" />
              <img src={forecast} alt="features item img" />
            </div>
          </div>
          <img className="features-tempImg" src={tempChart} alt="temp chart" />
        </div>
      </div>

      <div className="features-grid">
        <div className="features-box">
          <div className="features-box-images">
            <div className="features-item-image">
              <img src={iPhoneFrameWCamera} alt="iPhone Frame" />
              <img src={privacy} alt="features item img" />
            </div>
            <div className="features-privacy-container">
              {t("features privacy note")}.
            </div>
          </div>
        </div>
        <div className="features-box">
          <div className="features-box-title">
            <AiOutlineSafety size={35} />
            <h2>{t("Privacy")}</h2>
          </div>
          <p>{t("Privacy details")}</p>
        </div>
      </div>
    </div>
  );
}

export default Features;
