import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import ale01 from "../../assets/ale01.jpeg";
import ale02 from "../../assets/ale02.jpeg";
import ale03 from "../../assets/ale03.jpeg";
import ste01 from "../../assets/ste01.jpeg";
import user001 from "../../assets/001.jpeg";
import user004 from "../../assets/004.jpeg";
import luca01 from "../../assets/luca01.jpeg";
import mockUp3Images from "../../assets/mockUp3Images.png";
import DownloadBtn from "../../components/DownloadBtn";
import AboutUs from "./AboutUs";

// const listOfImages = [
//   { id: 0, imgSrc: ale01 },
//   { id: 1, imgSrc: luca01 },
//   { id: 2, imgSrc: ale02 },
//   { id: 3, imgSrc: ste01 },
//   { id: 4, imgSrc: ale03 },
//   { id: 5, imgSrc: user001 },
//   { id: 6, imgSrc: user004 },
// ];

const backgroundImages = [ale01, luca01, ale02, ste01, ale03, user001, user004];

export default function TopBanner() {
  const [transition, setTransition] = useState("");
  const [thumbnailAnimation, setThumbnailAnimation] =
    useState("hide-thumbnail");
  const [arrayOfImages, setArrayOfImages] = useState(backgroundImages);

  const { t } = useTranslation();

  useEffect(() => {
    const interval = setInterval(() => {
      setArrayOfImages(moveItem(arrayOfImages, 0, arrayOfImages.length - 1));
      transit();
      transitThumbnails("next");
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [arrayOfImages]);

  const transit = () => {
    setTransition("transition-fade");
    setTimeout(() => {
      setTransition("");
    }, 500);
  };

  const transitThumbnails = (dir) => {
    if (dir === "prev") {
      setThumbnailAnimation("transition-thumbnailsPrev");
    } else {
      setThumbnailAnimation("transition-thumbnailsNext");
    }
    // setThumbnailAnimation("transition-thumbnailsOut");
    setTimeout(() => {
      setThumbnailAnimation("hide-thumbnail");
    }, 500);
  };

  const moveItem = (array, from, to) => {
    const item = array[from];
    array.splice(from, 1);
    array.splice(to, 0, item);
    return array;
  };

  const handleOnClick = useCallback(
    (arrow) => {
      transitThumbnails(arrow);
      if (arrow === "prev") {
        setArrayOfImages(moveItem(arrayOfImages, arrayOfImages.length - 1, 0));
      } else {
        setArrayOfImages(moveItem(arrayOfImages, 0, arrayOfImages.length - 1));
      }
      transit();
    },
    [arrayOfImages]
  );

  return (
    <div className="top-banner-container">
      <img
        src={arrayOfImages[0]}
        className={`${transition} carousel-backgroundImage`}
        alt="home background"
        // id="animate-image"
      />

      <div className="above-carousel-container">
        <div id="transition-left">
          <h1>{t("Sport Fishing App")}</h1>
          <AboutUs />
          <h2>{t("Download the app")}</h2>
          <DownloadBtn flexTo={false} />
        </div>
        <div id="transition-right">
          <img src={mockUp3Images} alt="mockUp" />
        </div>
      </div>

      <div className="above-carousel-button">
        <div className="arrows">
          <button id="prev" onClick={() => handleOnClick("prev")} type="button">
            &#10094;
          </button>
          <button id="next" onClick={() => handleOnClick("next")} type="button">
            &#10095;
          </button>
        </div>

        <div className="thumbnail">
          {arrayOfImages.map((imgsrc, idx) => (
            <div
              key={idx}
              className="item"
              id={idx === 0 ? thumbnailAnimation : "transition-thumbnailsNext"}
            >
              <img src={imgsrc} alt="thumbnail item" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
