import React from "react";
import { Link } from "react-router-dom";
import { FaFacebookSquare, FaInstagram } from "react-icons/fa";
import { useTranslation } from "react-i18next";

import DownloadBtn from "../components/DownloadBtn";

function MainFooter() {
  const { t } = useTranslation();

  const currYear = new Date().getFullYear();

  return (
    <footer>
      <div>
        <div className="footer-content">
          <div>
            <h2>{t("Follow us")}</h2>
            <Link
              to="https://www.facebook.com/fishingfinity/"
              target="_blank"
              rel="noopener noreferrer"
              className="link-social-icon"
            >
              <FaFacebookSquare size={30} />
            </Link>
            <Link
              to="https://www.instagram.com/fishingfinity/"
              target="_blank"
              rel="noopener noreferrer"
              className="link-social-icon"
            >
              <FaInstagram size={30} />
            </Link>
          </div>
          <div>
            <h2>{t("Terms of Use")}</h2>
            <div className="terms-container">
              <Link to="privacyAndPolicy" className="linkText pb-10">
                {t("Privacy and Policy")}
              </Link>
              <Link to="termsAndConditions" className="linkText">
                {t("Terms and Conditions")}
              </Link>
            </div>
          </div>
          <div>
            <h2>{t("Support")}</h2>
            <Link to="support" className="linkText">
              {t("Contact Us")}
            </Link>
          </div>
        </div>
        <div className="pt-10">
          <h2 className="bold">{t("Download the app")}</h2>
          <DownloadBtn flexTo={true} />
        </div>
      </div>
      <div className="copyright-container">
        <span>
          Copyright © 2023-{currYear} Arbest S.N.C. All Rights Reserved.| P.IVA
          02042940474
        </span>
      </div>
    </footer>
  );
}

export default MainFooter;
