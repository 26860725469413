import React from "react";
import { QRCode } from "react-qrcode-logo";
import { useTranslation } from "react-i18next";

import apple from "../images/apple-logo-white.png";
import google from "../images/google-logo-transparent.png";
import appLogo from "../images/logoBlue.png";

export default function QrCodeScreen() {
  const { t } = useTranslation();

  const storeLink = [
    {
      id: 0,
      name: "googleQrCode",
      title: "Google Play Store",
      link: "https://play.google.com/store/apps/details?id=com.tycoonlures.fishingfinity&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1",
      color: "#ed3a26",
      icon: google,
    },
    {
      id: 1,
      name: "appleQrCode",
      title: "App Store",
      link: "https://apps.apple.com/it/app/fishingfinity/id1616848813",
      color: "#3a6bf2",
      icon: apple,
    },
  ];

  // const downloadCode = (name) => {
  //   const canvas = document.getElementById(name);
  //   if (canvas) {
  //     const pngUrl = canvas
  //       .toDataURL("image/png")
  //       .replace("image/png", "image/octet-stream");
  //     let downloadLink = document.createElement("a");
  //     downloadLink.href = pngUrl;
  //     downloadLink.download = `${name}.png`;
  //     document.body.appendChild(downloadLink);
  //     downloadLink.click();
  //     document.body.removeChild(downloadLink);
  //   }
  // };

  return (
    <div className="qr-code-container">
      <h1>{t("Download App")}</h1>
      <div className="qr-box">
        {storeLink.map(({ id, title, link, color, icon, name }) => {
          return (
            <div key={id} className="qr-box-item">
              <a href={link}>
                <img alt={title} src={icon} />
                <p>{title}</p>
              </a>

              <p>{t("or")}</p>

              <div>
                <QRCode
                  value={link}
                  size={250}
                  logoImage={appLogo}
                  logoHeight={50}
                  logoWidth={60}
                  logoOpacity={1}
                  ecLevel="Q"
                  removeQrCodeBehindLogo={true}
                  logoPadding={10}
                  logoPaddingStyle="circle"
                  enableCORS={true} // enabling CORS, this is the thing that will bypass that DOM check
                  qrStyle="dots" // type of qr code, wether you want dotted ones or the square ones
                  eyeRadius={[
                    {
                      outer: [10, 10, 0, 10],
                      inner: [0, 10, 10, 10],
                    },
                    [10, 10, 10, 0],
                    [10, 0, 10, 10],
                  ]}
                  eyeColor={"#2B65EC"}
                  fgColor={"#2B65EC"}
                  // bgColor="#2B65EC"
                  // bgColor='transparent'
                  quietZone={30}
                  id={name}
                />
              </div>

              {/* <button type="button" onClick={() => downloadCode(name)}>
                t(Download QR Code)
              </button> */}
            </div>
          );
        })}
      </div>
    </div>
  );
}
