import { useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogPanel,
  Description,
  DialogBackdrop,
} from "@headlessui/react";
import { useTranslation } from "react-i18next";

export default function PopUp({ open, closeModal }) {
  const cancelButtonRef = useRef(null);
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={() => closeModal()}
      transition
      className="relative z-50"
    >
      <DialogBackdrop className="fixed inset-0 bg-black/40" />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <DialogPanel className="max-w-lg space-y-4 border bg-white p-12">
          <DialogTitle className="font-bold">{t("Message sent")}</DialogTitle>
          <Description>
            {t("Thank you for contacting us. We will get back to you soon.")}
          </Description>
          <div className="centerItem">
            <button
              type="button"
              className="blue-btn"
              onClick={closeModal}
              ref={cancelButtonRef}
            >
              Ok
            </button>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
}
