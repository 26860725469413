import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";

import bgBot from "../../images/bg05.JPG";
import { ImagesIT } from "../../assets/androidSSIt";
import { ImagesEN } from "../../assets/androidSSEn";

function SlideShow() {
  const [datImg, setDatImg] = useState(ImagesIT);
  const [idx, setIdx] = useState(0);

  const { i18n } = useTranslation();
  // const { t } = useTranslation();

  const lang = i18n.language;

  useEffect(() => {
    if (lang === "it") {
      setDatImg(ImagesIT);
    } else {
      setDatImg(ImagesEN);
    }
  }, [lang]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIdx((prevIndex) =>
        prevIndex === datImg.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [idx]);

  const handleOnClick = useCallback(
    (arrow) => {
      if (arrow === "prev") {
        setIdx((prevIndex) =>
          prevIndex === 0 ? datImg.length - 1 : prevIndex - 1
        );
      } else {
        setIdx((prevIndex) =>
          prevIndex === datImg.length - 1 ? 0 : prevIndex + 1
        );
      }
    },
    [idx]
  );

  return (
    <div className="bot-slideShow-container">
      <img src={bgBot} className="bot-carousel-backgroundImage" alt="bgBot" />
      <div className="bot-carousel-container">
        <img src={datImg[idx].img} alt="slide show mockup" />
        {/* <p>{t(datImg[idx].caption)}</p> */}
      </div>
      <div className="bot-carousel-arrows">
        <button id="prev" onClick={() => handleOnClick("prev")} type="button">
          &#10094;
        </button>
        <button id="next" onClick={() => handleOnClick("next")} type="button">
          &#10095;
        </button>
      </div>
      <div className="carousel-dot-container">
        {datImg.length !== 0 &&
          datImg.map((d, i) => {
            return (
              <span
                key={d.id}
                style={{
                  backgroundColor: i === idx ? "rgb(129, 252, 5)" : "#bbb",
                }}
              />
            );
          })}
      </div>
    </div>
  );
}

export default SlideShow;
