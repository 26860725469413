import React, { useState, useEffect } from "react";

import logoHorizontal from "../images/LogoHorizontal.png";
import MobileMenu from "./mobileMenu";
import MainMenu from "./mainMenu";
import DropDownMenu from "../components/DropDownMenu";
import { useCallback } from "react";

function NavBar() {
  const [isOpen, setIsOpen] = useState(false);
  const [navColor, setnavColor] = useState("transparent");
  const [borderWidth, setborderWidth] = useState("0px");
  const [position, setposition] = useState("fixed");

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, [isOpen]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const listenScrollEvent = () => {
    window.scrollY > 10
      ? setnavColor("rgb(38 38 38)")
      : setnavColor("transparent");
    window.scrollY > 10 ? setborderWidth("1px") : setborderWidth("0px");
  };

  const handleOpenMenu = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  function handleWindowSizeChange() {
    if (window.innerWidth >= 1024) {
      setposition("fixed");
      setIsOpen(false);
      setnavColor("transparent");
      // setnavSize("7rem");
    }
  }

  // const isTransitioning = width <= 1023;

  return (
    <header>
      <nav
        style={{
          backgroundColor: isOpen ? "rgba(30,41, 59, 0.5)" : navColor,
          height: "5rem",
          position: position,
          // marginBottom: isOpen ? 50 : 0,
          borderBottomWidth: borderWidth,
          borderColor: "white",
          width: "100%",
          zIndex: 20,
          transition: "all 1s",
        }}
      >
        <div className="nav-container">
          <div className="mobileMenu-container">
            <button
              onClick={handleOpenMenu}
              type="button"
              className="menu-button"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              {!isOpen ? (
                <svg
                  className="menu-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              ) : (
                <svg
                  className="menu-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
          </div>
          <img className="top-logo" src={logoHorizontal} alt="App Logo" />
          <MainMenu />
          <DropDownMenu />
        </div>
        {isOpen && (
          <div className="menu-transition">
            <MobileMenu />
          </div>
        )}
      </nav>
    </header>
  );
}

export default NavBar;
